import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[30px] md:bottom-[10px] lg:bottom-[100px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <img
                            alt="EXP"
                            src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/banderas%2F12.png?alt=media&token=e4a59497-b8e1-48d4-856a-a8506357fd64"
                            className="w-28 md:w-44 mx-auto"
                        />
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                        <img className="w-[300px] mx-auto md:w-[300px] md:-mt-[100px] md:absolute" src="https://firebasestorage.googleapis.com/v0/b/archivos-fc4bd.appspot.com/o/archivos_varios%2Fsatisfaction%20guarenteed%20google-01-01.png?alt=media&token=ce80dadb-58f4-4b62-9f47-e01e3e7dda6b" alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;